// Styles
// CoreUI Icons Set
@import '../node_modules/@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '../node_modules/flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '../node_modules/font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '../node_modules/simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
